import React from "react"

import Typography from "@material-ui/core/Typography"

import useStyles from "./keyNumber.style"
import clsx from "clsx"

export default function KeyNumber({
  gutterBottom,
  title,
  description,
  gutterTop,
}) {
  const classes = useStyles()

  const rootClasses = clsx({
    [classes.root]: true,
    [classes.gutterBottom]: gutterBottom,
    [classes.gutterTop]: gutterTop,
  })

  return (
    <div className={rootClasses}>
      <Typography variant="body1" color="primary" style={{ fontWeight: 600 }}>
        {title}
      </Typography>
      <Typography variant="h5" color="initial">
        {description}
      </Typography>
    </div>
  )
}
