import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  link: {
    display: "block",
    padding: theme.spacing(0.5, 0),
    textDecoration: "underline",

    "&:hover": {
      cursor: "pointer",
    },
  },
}))

export default useStyles
