import React, { useState, useEffect } from "react"

import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"

import Layout from "../components/layout/layout"

import NewsletterBanner from "../components/general/newsletterBanner"
import KeyNumber from "../components/general/keyNumber"
import MoreInfoItem from "../components/general/moreInfoItem"

import useStyles from "./index.style"
import SEO from "../components/seo"
import useWamUrl from "../hooks/useWamUrl"
// import { useTheme } from "@material-ui/core"

export default function Presse() {
  const classes = useStyles()
  // const theme = useTheme()

  const [height, setHeight] = useState(0)
  useEffect(() => {
    setHeight(window.innerHeight)
  }, [])

  const { redirectToWam } = useWamUrl()

  return (
    <Layout animatedNavbar>
      <SEO />
      <div
        className={`${classes.header} ${classes.presseHeader}`}
        style={{ height: height }}
      >
        <div className={classes.textWrapper}>
          <Typography variant="h1" color="inherit" align="center">
            Presse
          </Typography>
          <Typography
            className={classes.subtitle}
            variant="h4"
            color="inherit"
            align="center"
          >
            Inshallah est un service d’échange qui tend à faciliter aux
            musulman(e)s la recherche de l’âme sœur en accord avec les valeurs
            humaines et morales de l’Islam.
          </Typography>

          <Button
            classes={{
              root: classes.signUpButton,
            }}
            onClick={() => redirectToWam("join")}
            disableRipple
            disableFocusRipple
          >
            Je m’inscrit !
          </Button>
        </div>
      </div>

      <div className={classes.iPhonesContent}>
        <Grid container spacing={2}>
          <Grid className={classes.textColumnMission} item xs={12} md={6}>
            <div
              className={classes.paragraphBlock}
              style={{ justifyContent: "flex-start" }}
            >
              <div className={classes.paragraphTextWrapper}>
                <Typography
                  variant="h6"
                  component="h3"
                  style={{ fontWeight: 700 }}
                >
                  <span role="img" aria-label="hearts emoji">
                    💛
                  </span>
                  &nbsp;À propos d’Inshallah.com
                </Typography>
                <Typography
                  className={classes.paragraphText}
                  variant="button"
                  component="p"
                >
                  Porta habitant ornare dignissim non vivamus sociosqu at enim
                  hendrerit senectus malesuada, tortor nullam fringilla nibh
                  laoreet nam taciti eleifend ex sodales. Vitae curabitur aenean
                  amet class mus consectetur, litora mollis eu duis sit est, hac
                  ipsum consequat hendrerit mi. Vestibulum posuere potenti
                  natoque et suspendisse vel convallis auctor, quis arcu vitae
                  maecenas phasellus suscipit integer ullamcorper.
                </Typography>
              </div>
            </div>
          </Grid>

          <Grid className={classes.textColumnMission} item xs={12} md={6}>
            <div className={classes.paragraphBlock}>
              <div className={classes.paragraphTextWrapper}>
                <Typography
                  variant="h6"
                  component="h3"
                  style={{ fontWeight: 700 }}
                >
                  Les chiffres clés&nbsp;
                  <span role="img" aria-label="hearts emoji">
                    🗝
                  </span>
                </Typography>
                <KeyNumber
                  title="Lancement d’Inshallah.com"
                  description="2011"
                  gutterTop
                  gutterBottom
                />
                <KeyNumber title="Employés" description="42" gutterBottom />
                <KeyNumber
                  title="Présent dans"
                  description="~175 pays"
                  gutterBottom
                />
                <KeyNumber
                  title="Unions par inshallah"
                  description="+250M"
                  gutterBottom
                />
                <KeyNumber
                  title="Messages envoyés"
                  description="+500M"
                  gutterBottom
                />
                <KeyNumber
                  title="Téléchargements des applications"
                  description="+700K"
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </div>

      <div className={classes.fullWidthRow}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              className={classes.fullWidthColumnTitle}
              variant="h1"
              component="h2"
            >
              Besoin de plus d’informations&nbsp;
              <span role="img" aria-label="hands shaking emoji">
                💌
              </span>
            </Typography>
          </Grid>
        </Grid>
      </div>

      <div className={classes.missionTextContent}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4} md={3}>
            <MoreInfoItem
              title="Demande d'informations"
              link="press@inshallah.com"
              additionalDescription="01 35 37 05 31"
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <MoreInfoItem
              title="Dossier de presse"
              link="Télécharger le dossier de presse"
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <MoreInfoItem title="Carrière" link="Découvrir les opportunités" />
          </Grid>
        </Grid>

        <div className={classes.joinBannerWrapperMission}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <NewsletterBanner />
            </Grid>
          </Grid>
        </div>
      </div>
    </Layout>
  )
}
