import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    padding: theme.spacing(0, 2),
  },
  gutterBottom: {
    marginBottom: theme.spacing(4),
  },
  wrapper: {
    position: "relative",
    width: "100%",
    // height: "100%",
    background: "linear-gradient(93.68deg, #FF5630 0%, #DE350B 100%)",
    borderRadius: theme.spacing(2),
    boxShadow:
      "0px 3px 5px rgba(33, 43, 54, 0.2), 0px 0px 1px rgba(33, 43, 54, 0.31)",
    color: "#FFF",
    padding: theme.spacing(5),
    minHeight: 212,
    maxWidth: "1080px",
    margin: `0 auto`,
    overflow: "hidden",
  },
  storesBadges: {
    display: "flex",
    marginTop: theme.spacing(2),
  },
  textWrapper: {
    position: "relative",
    zIndex: 1,
  },
  emoticons: {
    zIndex: 0,
    display: "none",
    position: "absolute",
    top: -2,
    right: -5,
    height: "calc(100% + 10px)",

    "@media screen and (min-width: 1048px)": {
      display: "block",
    },
  },
  inputWrapper: {
    marginTop: theme.spacing(1.5),
  },
  input: {
    width: "calc(100% - 109px)",
    maxWidth: 326,
    border: "1px solid #FF8F73",
    height: 56,
    backgroundColor: "transparent",
    borderTopLeftRadius: theme.spacing(1),
    borderBottomLeftRadius: theme.spacing(1),
    padding: theme.spacing(2),
    ...theme.typography.button,

    "&::placeholder": {
      color: "#FF8F73",
      opacity: 1,
    },

    "&:-ms-input-placeholder": {
      color: "#FF8F73",
    },

    "&::-ms-input-placeholder": {
      color: "#FF8F73",
    },
  },
  buttonRoot: {
    backgroundColor: "#FFF",
    borderRadius: 0,
    borderTopRightRadius: theme.spacing(1),
    borderBottomRightRadius: theme.spacing(1),
    height: 56,
    verticalAlign: "top",
    boxShadow: "initial",
    width: 109,
  },
  buttonLabel: {
    ...theme.typography.body2,
    fontWeight: 500,
    color: "#E83C30",
  },
}))

export default useStyles
