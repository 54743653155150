import React from "react"

import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"

import emoticons from "../../images/newsletterBanner/emoticons.png"

import useStyles from "./newsletterBanner.style"

export default function JoinBanner({ gutterBottom, removeImage }) {
  const classes = useStyles()

  return (
    <div className={`${classes.root} ${gutterBottom && classes.gutterBottom}`}>
      <div className={classes.wrapper}>
        {!removeImage && (
          <img
            className={classes.emoticons}
            src={emoticons}
            alt="multiple emojis"
          />
        )}
        <div className={classes.textWrapper}>
          <Typography variant="h5" color="inherit">
            Inscrivez-vous à notre newsletter&nbsp;
            <span role="img" aria-label="heart icon">
              💌
            </span>
          </Typography>
          <Typography variant="button" color="inherit">
            Ne loupez plus un seul de nos articles, inscrivez-vous à notre
            newsletter !
          </Typography>
          <div className={classes.inputWrapper}>
            <input
              className={classes.input}
              placeholder="Saisissez votre adresse e-mail"
            />
            <Button
              classes={{ root: classes.buttonRoot, label: classes.buttonLabel }}
              variant="contained"
              color="inherit"
              disableRipple
              disableFocusRipple
            >
              M’inscrire
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
