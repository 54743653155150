import React from "react"

import Typography from "@material-ui/core/Typography"

import useStyles from "./moreInfoItem.style"
import Link from "./Link"

export default function KeyNumber({ title, link, additionalDescription }) {
  const classes = useStyles()

  return (
    <div>
      <Typography variant="h4" color="initial" style={{ fontWeight: 600 }}>
        {title}
      </Typography>
      <Link className={classes.link} color="primary">
        <Typography variant="body2" color="inherit">
          {link}
        </Typography>
      </Link>
      <Typography variant="body2" color="initial">
        {additionalDescription}
      </Typography>
    </div>
  )
}
