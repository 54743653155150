import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  root: {},
  gutterBottom: {
    marginBottom: theme.spacing(2),
  },
  gutterTop: {
    marginTop: theme.spacing(0.5),
  },
}))

export default useStyles
